import React, { useState, useEffect, useCallback } from "react"
import Layout from "../components/Layout"
import "../styles/main.scss"
import { StaticImage } from "gatsby-plugin-image"

export default function Home() {
  const [isCombineExpand, setIsCombineExpand] = useState(false)
  const [isAdExpand, setIsAdExpand] = useState(false)
  const [abslouteVideo, setAbslouteVideo] = useState("")

  useEffect(() => {
    const body = document.querySelector("body")
    if (abslouteVideo) {
      body.style.overflow = "hidden"
      typeof document != "undefined" &&
        document.addEventListener("mousedown", handleClickOutsidePopup, false)
    } else {
      body.style.overflow = "auto"
      typeof document != "undefined" &&
        document.removeEventListener(
          "mousedown",
          handleClickOutsidePopup,
          false
        )
    }
  }, [abslouteVideo])

  const handleClickOutsidePopup = useCallback(
    e =>
      !e?.target?.classList?.contains("absloute-video") && setAbslouteVideo(""),
    []
  )

  return (
    <Layout>
      <div className="main">
        <div className="first-block">
          <div className="first-block-text">
            <h1>
              A new revolutionary app combining shopping & discounts with fun &
              games
            </h1>
            <h2>
              Download CATSH now and immediately start enjoying great deals and
              collecting FREE MONEY!
            </h2>
            <div className="stores-row">
              <StaticImage
                className="app-store"
                src="../images/app-store.png"
                alt="app-store"
              />
              <StaticImage
                className="google-play"
                src="../images/google-play.png"
                alt="google-play"
              />
            </div>
          </div>
          <StaticImage
            className="hero-image"
            src="../images/hero.png"
            alt="hero"
            layout="constrained"
          />
        </div>
        <div className="second-block" id="second-block">
          <StaticImage
            className="tree-left"
            src="../images/tree-left.png"
            alt="tree-left"
          />
          <StaticImage
            className="tree-right"
            src="../images/tree-right.png"
            alt="tree-right"
          />
          <div className="texts">
            <h1>Catsh App</h1>
            <h2>
              Welcome to Catsh, an exciting new app where money does grow on
              trees!
            </h2>
            <p>
              Catsh allows you to have fun collecting virtual coins and other
              goodies as you walk around your neighborhood, the streets, or
              pretty much anywhere you go.It’s simple, easy, intuitive and fun –
              and you decide how to spend your cash! Start playing Catsh and
              enjoy endless discounts, promotions and coupons while playing
              having a great time!
            </p>
            <div className="stores-row">
              <StaticImage
                className="app-store"
                src="../images/app-store.png"
                alt="app-store"
              />
              <StaticImage
                className="google-play"
                src="../images/google-play.png"
                alt="google-play"
              />
            </div>
          </div>
          <StaticImage
            className="mockups"
            src="../images/mockups.png"
            alt="mockups"
          />
          <StaticImage
            className="steps-desktop"
            src="../images/steps-desktop.png"
            alt="steps-desktop"
          />
          <StaticImage
            className="steps-mobile"
            src="../images/steps-mobile.png"
            alt="steps-mobile"
          />
          <p className="video-title">
            See how playing Catsh can earn you money and other goodies
          </p>
          <div className="relative-iframe">
            <iframe
              title="invite freind"
              src="https://player.vimeo.com/video/661772836?h=a46de9aae7"
              allow="autoplay"
            />
            <StaticImage
              className="marker-first"
              src="../images/catsh-marker.png"
              alt="marker-first"
            />
            <StaticImage
              className="marker-second"
              src="../images/catsh-marker.png"
              alt="marker-second"
            />
          </div>
        </div>
        <div className="first-divider">
          <StaticImage
            className="tree-left"
            src="../images/tree-left.png"
            alt="tree-left"
          />
          <StaticImage
            className="tree-right"
            src="../images/tree-right.png"
            alt="tree-right"
          />
          <div className="content">
            <StaticImage className="logo" src="../images/logo.png" alt="logo" />
            <p>
              Where money
              <b> does</b> grow on trees!
            </p>
          </div>
        </div>
        <div className="third-block" id="third-block">
          <div className="texts">
            <h1>Catsh for Businesses</h1>
            <h2>Put your business on the map</h2>
            <p>
              An amazing new sales and marketing platform. All the advantages of
              an extremely sophisticated customer club and much more, free and
              just a few clicks away. In Catsh you, the business, distribute
              virtual coins and coupons to the players through various Catsh
              marketing campaigns. Catsh players collect the coins and are
              exposed to the deals and discounts you offer and then, when they
              buy from you, they cover the discount amount with their Catsh
              coins.
            </p>
            <ul>
              <li>Promote, expose and advertise your business for free!</li>
              <li>
                Bring relevant clients to your shop with several different kinds
                of marketing campaigns!
              </li>
              <li>It's easy, simple, no expertise is required!</li>
            </ul>
            <div className="links">
              <a
                href="https://b.catsh.app/auth/registration"
                target="_blank"
                rel="noreferrer"
                className="get-started-btn"
              >
                Get Started - It’s Free!
              </a>
              <div className="already-player">
                Already a memeber?{" "}
                <a
                  href="https://b.catsh.app/index.php/auth/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign in
                </a>
              </div>
            </div>
          </div>
          <StaticImage
            className="laptop"
            src="../images/laptop.png"
            alt="laptop"
          />
          <p className="video-title">See how Catsh can benefit your business</p>
          <div className="relative-iframe">
            <iframe
              title="invite-business"
              src="https://player.vimeo.com/video/661772898?h=b055b05366"
            />
            <StaticImage
              className="marker marker-first"
              src="../images/catsh-marker.png"
              alt="marker-first"
            />
            <StaticImage
              className="marker marker-second"
              src="../images/catsh-marker.png"
              alt="marker-second"
            />
            <StaticImage
              className="marker marker-third"
              src="../images/catsh-marker.png"
              alt="marker-third"
            />
            <StaticImage
              className="marker marker-fourth"
              src="../images/catsh-marker.png"
              alt="marker-fourth"
            />
            <StaticImage
              className="marker marker-fifth"
              src="../images/catsh-marker.png"
              alt="marker-fifth"
            />
          </div>
        </div>
        <div className="second-divider">
          <StaticImage
            className="tree-left"
            src="../images/tree-left.png"
            alt="tree-left"
          />
          <StaticImage
            className="tree-right"
            src="../images/tree-right.png"
            alt="tree-right"
          />
          <div className="content">
            <StaticImage className="logo" src="../images/logo.png" alt="logo" />
            <p>Puts your business on the map</p>
          </div>
        </div>
        <div className="fourth-block">
          <h1>Campaign for every purpose</h1>
          <h2>Catsh brings clients flooding into your shop</h2>
          <div className="content">
            <div className="block">
              <StaticImage
                className="img-header"
                src="../images/coins-header.png"
                alt="coins-header"
              />
              <h3>Coins</h3>
              <h4>Advertise with sponsored coins</h4>
              <h5>
                Clients see an ad for your business when they collect Catsh
                coins.
              </h5>
              <div className="divider" />
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>Choose where to distribute Catsh coins on the map</p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Catsh users collect coins all the time, so they will see your
                  ad very often!
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Place coins near your shop to physically attract clientele
                  when they come to collect the coins!
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Pay per exposure (number of times your ad appears to clients)
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Recycle coins: When clients buy at your shop, you can
                  redistribute some of the coins in a new campaign!
                </p>
              </div>
              <span
                role="button"
                tabIndex={-1}
                onClick={() =>
                  setAbslouteVideo(
                    "https://player.vimeo.com/video/661772929?h=569ccb2fe3"
                  )
                }
                onKeyPress={event =>
                  event.key === "Enter" && setIsCombineExpand(prev => !prev)
                }
                className="video-btn"
              >
                <StaticImage
                  className="play"
                  src="../images/play.png"
                  alt="play"
                />
                WATCH VIDEO
              </span>
            </div>
            <div className="block">
              <StaticImage
                className="img-header"
                src="../images/coupons-header.png"
                alt="coupons-header"
              />
              <h3>Coupons</h3>
              <h4>Targeted personalized advertising</h4>
              <h5>Distribute coupons to relevant potential customers.</h5>
              <div className="divider" />
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Catsh distributes coupons for your custom deals to specific
                  users
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>Promote specific deals with each coupon campaign!</p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Customize your customers: Choose the age, gender, and location
                  of who gets your coupons
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Pay per coupon (number of coupons actually collected/received
                  by clients)
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Expose your business to new clientele with immediate incentive
                  to shop at your shop!
                </p>
              </div>
              <span
                role="button"
                tabIndex={-1}
                onClick={() =>
                  setAbslouteVideo(
                    "https://player.vimeo.com/video/661772872?h=0269260040"
                  )
                }
                onKeyPress={event =>
                  event.key === "Enter" && setIsCombineExpand(prev => !prev)
                }
                className="video-btn"
              >
                <StaticImage
                  className="play"
                  src="../images/play.png"
                  alt="play"
                />
                WATCH VIDEO
              </span>
            </div>
            <div></div>
            <div className="block">
              <StaticImage
                className="img-header map"
                src="../images/map-header.png"
                alt="map-header"
              />
              <h3>Map</h3>
              <h4>Put your shop on the map</h4>
              <h5>Mark your business on the Catsh map for brand awareness</h5>
              <div className="divider" />
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  A map marker with your logo will show everyone your shop
                  location
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Clients can just click the marker to go straight to your
                  business’ page!
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Place the map marker at the address of your shop, or wherever
                  you choose!
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  Pay for a set time (how long the shop will be marked on the
                  Catsh map)
                </p>
              </div>
              <div className="info-row">
                <StaticImage className="v" src="../images/v.png" alt="v" />
                <p>
                  During the campaign, any Catsh clients in the area see your
                  shop on the map!
                </p>
              </div>
              <span
                role="button"
                tabIndex={-1}
                onClick={() =>
                  setAbslouteVideo(
                    "https://player.vimeo.com/video/661772958?h=45205b5642"
                  )
                }
                onKeyPress={event =>
                  event.key === "Enter" && setIsCombineExpand(prev => !prev)
                }
                className="video-btn"
              >
                <StaticImage
                  className="play"
                  src="../images/play.png"
                  alt="play"
                />
                WATCH VIDEO
              </span>
            </div>
          </div>
          <StaticImage
            className="fourth-block-bottom"
            src="../images/fourth-block-bottom.png"
            alt="fourth-block-bottom"
          />
        </div>
        <div className="fifth-block" id="fifth-block">
          <h1>Our Story</h1>
          <h2>Combining shopping and gaming</h2>
          <StaticImage
            className="combine-shopping"
            src="../images/combine-shopping.png"
            alt="combine-shopping"
          />
          <div className="first-p">
            Catsh allows you to have fun collecting virtual cash and other
            goodies by playing games as you walk around your neighborhood, the
            streets, or pretty much anywhere you go.
            <br /> It’s simple, easy, intuitive and fun, and you decide how to
            use your coins!
            <p className={`read-more ${isCombineExpand ? "open" : ""}`}>
              The application and its use are completely free, no hidden
              charges! All you have to do is to download the app, sign in and
              start collecting cash. Manage your coins, coupons, and all the
              goodies you’ve collected using the application’s wallet. When you
              decide to buy something with Catsh, simply go to our search page
              to find the products or services you want in nearby Catsh member
              shops or on-line businesses. Check out the exclusive discounts
              specially offered to Catsh players and then, when you pay for
              whatever you’ve decided to buy, use your Catsh coins to cover a
              part of the payment. Just like real money. You can collect and use
              Catsh coins anywhere around the world, enjoy great deals and
              discounts, and turn your shopping experience super fun and
              exciting! It’s simple, easy, intuitive and fun, and you decide how
              to use your coins!
            </p>
            <span
              role="button"
              tabIndex={-1}
              onClick={() => setIsCombineExpand(prev => !prev)}
              onKeyPress={event =>
                event.key === "Enter" && setIsCombineExpand(prev => !prev)
              }
            >
              {isCombineExpand ? "Show less" : "Read more"}
              <StaticImage
                className={`chevron ${isCombineExpand ? "reverse" : ""}`}
                src="../images/chevron.png"
                alt="chevron"
              />
            </span>
          </div>
          <div className="advertising">
            <StaticImage
              className="advertising-img"
              src="../images/sale.png"
              alt="sale"
            />
            <h2>Advertising made easy</h2>
            <div className="second-p">
              Marketing nowadays can be challenging and as a business owner,
              bringing clients through you door can be expensive. Managing
              advertising campaigns requires expertise, time, and lots of money.
              Customer clubs, loyalty points, personalized coupons? Not every
              business can afford their own special app.
              <div className={`read-more ${isAdExpand ? "open" : ""}`}>
                Introducing Catsh, a revolutionary app that allows you to
                promote your business easily and for free. In Catsh you
                distribute virtual coins and coupons on the game map as part of
                your Catsh marketing campaign. Catsh players collect the coins
                and are then exposed to the deals and discounts you offer and
                then, when they buy from you, they cover the discount amount
                with their Catsh coins. So how do you join? A quick and easy
                sign-up: create your business profile, set your Catsh discount,
                and you’re ready to go. Your business is now on the map! As well
                as in the app’s Search results, where shoppers can find you
                easily and simply. In addition, you’ll receive a free monthly
                marketing budget which you can use towards your Catsh marketing
                campaigns. - In the Coins campaign you can spread Catsh coins
                anywhere on the map for clients to collect on the move. When
                players collect a coin they’ll see your info
                <ul>
                  <li>
                    The Coupons campaign allows you to distribute Personal
                    Coupons, and is great for promoting specific deals to
                    specific clients
                  </li>
                  <li>
                    Choose the Map campaign to feature your business on the map
                    so any nearby clients immediately notice your business
                    location, logo, and name
                  </li>
                  <li>
                    The Search campaign bumps up your business in the app search
                    results for clients to find you quickly and effortlessly.
                  </li>
                </ul>
                You can easily do it all yourself! Quick, simple, fast and easy.
                No expensive marketing consultants or complicated strategies.
                Manage your campaigns easily! Catsh provides you with an online
                back-office where you can monitor the progress of your campaigns
                and see your results. Catsh brings clients flooding into your
                shop!
              </div>
              <span
                onKeyPress={event =>
                  event.key === "Enter" && setIsAdExpand(prev => !prev)
                }
                role="button"
                tabIndex={-1}
                onClick={() => setIsAdExpand(prev => !prev)}
              >
                {isAdExpand ? "Show less" : "Read more"}
                <StaticImage
                  className={`chevron ${isAdExpand ? "reverse" : ""}`}
                  src="../images/chevron.png"
                  alt="chevron"
                />
              </span>
            </div>
          </div>
        </div>
        <div className={`absloute-video ${abslouteVideo ? "open" : ""}`}>
          <iframe title="campain video" src={abslouteVideo} />
        </div>
      </div>
    </Layout>
  )
}
